// import http from "../axios/http-common";
import axios from "@/axios";
import Swal, { swal } from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
const numeral = require("numeral");
const EventEmitter = require("events");
const eventBus = new EventEmitter();

const warningText = "هل أنت متأكد من هذا الإجراء؟";
const warningTextDelete = "!! سوف يتم حذف هذا العنصر ولن تتمكن من أسترجاعة ";

const propSwalAlertConfirm = {
    title: warningText,
    text: warningTextDelete,
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: "لا",
    confirmButtonText: "نعم"
};

class genericService {
    async getAll(controler) {
        try {
            return await axios.get(controler);
        } catch (error) {
            return error;
        }
    }

    async get(controler) {
        return await axios.get(controler);
    }

    async create(controler, data) {
        return await axios.post(controler, data);
    }

    async update(id, data) {
        return await axios.put(id, data);
    }

    async delete(controler, id) {
        return await axios.delete(`${controler}/${id}`);
    }

    swalAlertConfirm(obj) {
        return Swal.fire({
            title: obj.title || propSwalAlertConfirm.title,
            text: obj.text || propSwalAlertConfirm.text,
            icon: propSwalAlertConfirm.icon,
            showCancelButton: propSwalAlertConfirm.showCancelButton,
            confirmButtonColor: propSwalAlertConfirm.confirmButtonColor,
            cancelButtonColor: propSwalAlertConfirm.cancelButtonColor,
            cancelButtonText: propSwalAlertConfirm.cancelButtonText,
            confirmButtonText: propSwalAlertConfirm.confirmButtonText
        });
    }

    swalAlertSuccess() {
        return Swal.fire({
            icon: "success",
            title: "اكتملت العملية بنجاح !",
            showConfirmButton: false,
            timer: 1000
        });
    }

    swalAlertError() {
        return Swal.fire({
            icon: "error",
            title: "حدث خطأ!!",
            confirmButtonText: "Cool"
        });
    }

    formatCurrency(x) {
        return numeral(x).format("0,0");
    }

    refresh(key) {
        console.log(key);
        eventBus.emit(key);
    }
}

export default new genericService();
