export default [{
        path: "/404",
        name: "pageNotFound",
        component: () =>
            import ("../views/404.vue"),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/forbidden",
        name: "forbidden",
        component: () =>
            import ("../views/forbidden.vue"),
        meta: {
            hideNavigation: true,
        },
    },

    {
        path: "/",
        redirect: "/branches",
    },
    // {
    //     path: "/home",
    //     name: "الرئيسية",
    //     component: () => import("../views/home/home.vue"),
    //     meta: {
    //         single: "الرئيسية",
    //         endPoint: "Home",
    //         breadcrumbs: [{ text: "الرئيسية", disabled: false }],
    //         requireAuth: true,
    //         auth: [1],
    //     },
    // },

    // {
    //     path: "/area",
    //     name: "المناطق",
    //     component: () => import("@/views/area/area.vue"),
    //     meta: {
    //         single: "منطقة",
    //         endPoint: "Area",
    //         breadcrumbs: [{ text: "الاقسام", disabled: false }],
    //         requireAuth: true,
    //         auth: [1],
    //     },
    // },
    {
        path: "/branches",
        name: "الفروع",
        component: () =>
            import ("@/views/branches/branches.vue"),
        meta: {
            single: "الفروع",
            endPoint: "Branches",
            breadcrumbs: [{ text: "الفروع", disabled: false }],
            requireAuth: true,
            auth: [1],
        },
    },
    {
        path: "/projects",
        name: "المشاريع",
        component: () =>
            import ("@/views/projects/projects.vue"),
        meta: {
            single: "المشاريع",
            endPoint: "Projects",
            breadcrumbs: [{ text: "المشاريع", disabled: false }],
            requireAuth: true,
            auth: [1],
        },
    },
    {
        path: "/slider",
        name: "السلايدر",
        component: () =>
            import ("@/views/slider/slider.vue"),
        meta: {
            single: "السلايدر",
            endPoint: "Sliders",
            breadcrumbs: [{ text: "السلايدر", disabled: false }],
            requireAuth: true,
            auth: [1],
        },
    },
    {
        path: "/news",
        name: "الاخبار",
        component: () =>
            import ("@/views/news/news.vue"),
        meta: {
            single: "الاخبار",
            endPoint: "News",
            breadcrumbs: [{ text: "الاخبار", disabled: false }],
            requireAuth: true,
            auth: [1],
        },
    },

    {
        path: "/commonQuestions",
        name: "الاسئلة الشائعة",
        component: () =>
            import ("@/views/commonQuestion/commonQuestion.vue"),
        meta: {
            single: "الاسئلة الشائعة",
            endPoint: "CommonQuestions",
            breadcrumbs: [{ text: "الاسئلة الشائعة", disabled: false }],
            requireAuth: true,
            auth: [1],
        },
    },
    {
        path: "/Investments",
        name: "الاستثمارات",
        component: () =>
            import ("@/views/Investments/Investments.vue"),
        meta: {
            single: "الاستثمارات",
            endPoint: "Investments",
            breadcrumbs: [{ text: "الاستثمارات", disabled: false }],
            requireAuth: true,
            auth: [1],
        },
    },

    // {
    //     path: "/myProfile",
    //     name: "الملف الشخصي",
    //     component: () => import("@/components/user/userDetails.vue"),
    //     meta: {
    //         single: "الرئيسية",
    //         endPoint: "Auth",
    //         breadcrumbs: [{ text: "الرئيسية", disabled: false }],
    //         requireAuth: true,
    //     },
    // },

    {
        path: "/login",
        name: "تسجيل الدخول",
        component: require("../views/auth/login.vue").default,
        meta: {
            hideNavigation: true,
        },
    },

    {
        path: "*",
        meta: {
            requireAuth: true,
        },
        redirect: "/404",
    },

    // {
    //     path: "/help",
    //     name: "مركز المساعدة",
    //     component: () => import("../views/help/help.vue"),
    //     meta: {
    //         breadcrumbs: [{ text: "مركز المساعدة", disabled: false }],
    //         endPoint: "Help",
    //         requireAuth: true,
    //     },
    // },

    {
        path: "/users",
        name: "المستخدمين",
        component: () =>
            import ("@/views/users/users.vue"),
        meta: {
            single: "مستخدم",
            endPoint: "Users",
            breadcrumbs: [{ text: "إدارة المستخدمين", disabled: false }],
            requireAuth: true,
            auth: [1],
        },
    },
    {
        path: "/userInvestmentsFlow/:id",
        name: "حركة الشريك",
        component: () =>
            import ("@/views/userInvestmentsFlow/userInvestmentsFlow.vue"),
        meta: {
            single: "شريك",
            endPoint: "Investments",
            breadcrumbs: [{ text: "حركات الشريك", disabled: false }],
            requireAuth: true,
            auth: [1],
        },
    },
    {
        path: "/profitRolls",
        name: "تذكيرات",
        component: () =>
            import ("@/views/profitRoll/profitRoll.vue"),
        meta: {
            single: "تذكيرات",
            endPoint: "ProfitRoll",
            breadcrumbs: [{ text: "تذكيرات", disabled: false }],
            requireAuth: true,
            auth: [1],
        },
    },
];