import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./axios";
import vuetify from "./plugins/vuetify";
import style from "./style/style.scss";
import validations from "@/utils/validations";
import "./utils/filters";
import VueNumeric from "vue-numeric-currency";
import vueDebounce from "vue-debounce";
import VueTheMask from "vue-the-mask";
import global from "./store/global";
import i18n from "@/libs/i18n";
import VueSweetalert2 from "vue-sweetalert2";
import genericService from "@/services/genericService";
import VueQRCodeComponent from "vue-qrcode-component";
// import Toast from "vue-toastification";
// Vue.use(Toast, {
//     transition: "Vue-Toastification__bounce",
//     maxToasts: 5,
//     newestOnTop: true,
//     position: "top-right",
// });

Vue.use(VueSweetalert2);

Vue.use(VueTheMask);
Vue.prototype.$phoneMask = "+964 #### ### ####";
Vue.prototype.$birthDate = "####/##/##";
Vue.prototype.$money = "#,###.##";
Vue.use(vueDebounce);
Vue.use(VueNumeric);
Vue.component("qr-code", VueQRCodeComponent);

Vue.prototype.$http = axios;
Vue.prototype.$validations = validations;
Vue.prototype.$global = global;
Vue.prototype.$genericService = genericService;

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

Vue.component("Loading", () => import("./components/Loading.vue"));
Vue.component("Pagination", () => import("./components/Pagination.vue"));

//401
axios.interceptors.response.use(
    (response) => {
        // console.log(response);
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {
        // console.log(error?.response?.status);
        if (error?.response?.status) {
            console.log("main error", error.response.status);
            if (error.response.status == 401) {
                localStorage.clear();
                router.replace({
                    path: "/login",
                    query: { redirect: router.currentRoute.fullPath },
                });
            }
            var errors =
                error?.response?.errors ?? error?.response?.data.errors;
            var message = error?.response?.data?.message ?? error?.message;
            if (message) {
                store.dispatch("toastification/setToast", {
                    message: `Error: ${message || "Unknown error"}`,
                    type: "error",
                });
            }
            console.log(typeof errors, Array.isArray(errors));

            if (typeof errors === "object" && errors !== null) {
                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        const element = errors[key];
                        console.log(element);
                        store.dispatch("toastification/setToast", {
                            message: `${key}: ${element || "Unknown error"}`,
                            type: "error",
                        });
                    }
                }
            }

            return Promise.reject(error.response);
        }
    }
);
//401

new Vue({
    router,
    store,
    i18n,
    vuetify,
    style,
    render: (h) => h(App),
}).$mount("#app");
