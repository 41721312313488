<template>
    <div>
        <v-main>
            <appbar v-if="!$route.meta.hideNavigation" class="d-print-none"/>
            <transition name="router-anim">
                <v-container fluid v-if="!$route.meta.hideNavigation">
                    <router-view :key="$route.path" />
                </v-container>
                <router-view :key="$route.path" v-else />
            </transition>
        </v-main>
    </div>
</template>

<script>
export default {
    name: "Mainlayout",
    components: {
        appbar: () => import("./Appbar"),
    },
};
</script>
