import config from "@/../public/static/config.json";
import axios from "axios";
let url =
    process.env.NODE_ENV === "production"
        ? config.APP_URL_PROD
        : config.APP_URL_DEV;
// const url = "https://api.samaaljawharah.com/";
// const url = "https://10.40.31.19:8081/";
//  config.APP_URL_DEV;
let token = localStorage.getItem("accessToken");

// console.log(userInfo);
export default axios.create({
    baseURL: url + "/api/",
    headers: {
        Authorization: {
            toString() {
                return `Bearer ${token}`;
            },
        },
        // "Cache-Control": "no-cache",
        // Pragma: "no-cache",
        // Expires: "0",
    },
});
