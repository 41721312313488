import Vue from "vue";
import moment from "moment";

// moment.updateLocale("en", {
//     months:
//         String[("1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12")]
// });

Vue.filter("formatDate", function(value) {
    if (value) {
        return value.substr(0, 10);
    }
});

Vue.filter("formatDateAgo", function(value) {
    if (value) {
        return moment(String(value)).fromNow(); // 4 years
    }
});

Vue.filter("formatFullDate", function(value) {
    if (value) {
        return moment(String(value)).locale("ar-ly").format('MMMM Do YYYY, h:mm:ss a');
    }
    console.log(moment.locale());
});

Vue.filter("uperCase", function(v) {
    return v.toUpperCase();
});

Vue.filter("phone", function(phone) {
    return phone
        .replace(/[^0-9]/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
});

var numeral = require("numeral");
Vue.filter("formatCurrency", function(value) {
    return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});
