<template>
  <div class="area d-flex justify-center align-center">
    <div class="d-flex flex-column">
      <!-- <v-img src="@/assets/img/sama_logo.png" class="ma-auto" width="60" /> -->
      <v-card width="450" class="pa-8 shadow">
        <div>
          <v-img src="@/assets/img/hello.png" class="ma-auto" width="120" />
          <h3 class="text-center">
            <b>مرحبا بك في عائلة سما بابل</b>

            <p class="font-weight-thin" style="opacity: 50%">استثمر بشكل آمن</p>
          </h3>
        </div>
        <v-card-title class="mt-4">
          <h4>تسجيل دخول</h4>
        </v-card-title>
        <v-card-text class="mt-n3"> مرحـبـاً بـعـودتــك </v-card-text>

        <v-form @submit.prevent="handleLogin" ref="form" v-model="valid" lazy-validation>
          <v-card-actions>
            <v-text-field
              v-model="user.userNameOrPhoneNumber"
              placeholder="إسم المستخدم"
              outlined
              :rules="[required()]"
            ></v-text-field>
          </v-card-actions>
          <v-card-actions class="mt-n4">
            <v-text-field
              v-model="user.password"
              placeholder="كلمة المرور"
              outlined
              :rules="[required()]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              :loading="loading"
              type="submit"
              dark
              block
              large
              class="btn"
              color="primary"
            >
              تسجيل الدخول
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import validations from "@/utils/validations";

import { v4 } from "uuid";
export default {
  name: "Login",

  data() {
    return {
      user: {},
      loading: false,
      valid: true,
      showPassword: false,
      required: [(v) => !!v || "هذا الحقل مطلوب"],
      ...validations,
      dateNow: new Date(),
    };
  },

  created() {
    this.user = {
      userNameOrPhoneNumber: "",
      password: "",
      idToken: "",
      clientId: v4(),
      clientType: "web",
    };
  },

  methods: {
    handleLogin() {
      this.$refs.form.validate();
      if (this.user.userNameOrPhoneNumber && this.user.password) {
        this.loading = true;
        this.$http
          .post("auth/login", this.user)
          .then((res) => {
            this.$http.defaults.headers["Authorization"] =
              "Bearer " + res.data.result.accessToken;
            localStorage.setItem("accessToken", res.data.result.accessToken);
            localStorage.setItem("userData", JSON.stringify(res.data.result));
            this.$store.dispatch("auth/login");
            this.$store.dispatch("toastification/setToast", {
              message: `تم تسجيل الدخول بنجاح`,
              type: "success",
            });
            this.$router.push("/");
          })
          .catch((err) => {
            console.log(err.response);
            this.$store.dispatch("toastification/setToast", {
              message: `${
                err.response.data?.message
                  ? err.response.data.message
                  : err.response.message
              }`,
              type: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.dispatch("toastification/setToast", {
          message: `الرجاء ادخل اسم المستخدم او كلمة المرور`,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.area {
  width: 100%;
  height: 100vh;
  // background-image: linear-gradient(180deg, rgb(0, 27, 81), rgb(4, 95, 231));
}

.box-login {
  margin-top: 40px;
  margin-left: -210px;
  z-index: 1000000000000 !important;
  background: rgba(255, 255, 255, 1) !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 1) !important;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(6, 71, 64, 0.5) !important;
}
</style>
