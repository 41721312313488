<template>
    <v-app id="app" :style="{ background: $vuetify.theme.themes[theme].background }">
        <mainlayout />
    </v-app>
</template>

<script>
import Mainlayout from "./layouts/Mainlayout.vue";

export default {
    data() {
        return {};
    },
    components: {
        Mainlayout,
    },
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        },
    },
    created() {},
};
</script>