export const auth = {
    namespaced: true,
    state: {
        userData: JSON.parse(localStorage.getItem("userData")),
        accessToken: localStorage.getItem("accessToken"),
    },

    mutations: {
        authUser(state, item) {
            state.userData = item.userData;
            state.accessToken = item.accessToken;
        },

        logout(state) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userData");
            localStorage.removeItem("unvInfo");
            localStorage.removeItem("dark_theme");
            localStorage.removeItem("userTraining-1");
            state.token = null;
            state.user = null;
            localStorage.clear();
        },
    },

    actions: {
        login({ commit }) {
            commit("authUser", {
                userData: JSON.parse(localStorage.getItem("userData")),
                accessToken: localStorage.getItem("accessToken"),
            });
        },

        logout({ commit }) {
            commit("logout");
        },
    },
};
