import Vue from "vue";
import Vuex from "vuex";
import axios from "../axios";
import validations from "@/utils/validations";
import { toastification } from "./modules/toastification.module";

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    state: {
        ...validations,
        loading: false,
        uploading: false,

        length: 0,
        filter: {
            pageIndex: 1,
            pageSize: 30,
            search: "",
            count: 0,
        },
        pageSizes: [5, 10, 15, 30, 50, 100],

        // Global variables
        cityId: "",

        provinceId: "",


        // lists
        areas: [],
        users: [],
        roles: [],
        branches: [],
        comQus: [],
        projects: [],
        sliders: [],
        news: [],


        provinces: [{
                id: 1,
                name: "بغداد",
            },
            {
                id: 2,
                name: "أربيل",
            },
            {
                id: 3,
                name: "الانبار",
            },
            {
                id: 4,
                name: "بابل",
            },
            {
                id: 5,
                name: "البصره",
            },
            {
                id: 6,
                name: "دهوك",
            },
            {
                id: 7,
                name: "النجف",
            },
            {
                id: 8,
                name: "الديوانيه",
            },
            {
                id: 9,
                name: "ديالى",
            },
            {
                id: 10,
                name: "ذي قار",
            },
            {
                id: 11,
                name: "السليمانيه",
            },
            {
                id: 12,
                name: "صلاح الدين",
            },
            {
                id: 13,
                name: "كركوك",
            },
            {
                id: 14,
                name: "كربلاء",
            },
            {
                id: 15,
                name: "المثنى",
            },
            {
                id: 16,
                name: "ميسان",
            },
            {
                id: 17,
                name: "نينوى",
            },
            {
                id: 18,
                name: "واسط",
            },
        ],

        // -----------


        // users and roles

        authGroups: [],

    },

    mutations: {},

    actions: {
        reset() {
            this.state.filter.search = "";
            this.state.categoryId = "";

        },


    },
    modules: {
        toastification,
    },
});