import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import global from "../store/global";
import routesModel from "./routes";


Vue.use(VueRouter);

const routes = [...routesModel];

const router = new VueRouter({
    mode: "history",
    // mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    // title tap changer
    // window.document.title = to.name ? to.name : "Hotel";

    global.dispatch("reset");
    if (to.matched.some((r) => r.meta.requireAuth)) {
        if (store.state.auth.accessToken == undefined || store.state.auth.accessToken == null) {
            next("/login");
        }

        //     if (store.state.auth.userData.roles && to.meta.auth) {
        //         for (var i = 0; i < to.meta.auth.length; i++) {
        //             if (store.state.auth.userData.userData.group.key == to.meta.auth[i]) {
        //                 return next();
        //             }
        //         }
        //         next("/forbidden");
        //     }
    }
    next();
});

export default router;